import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "blue-dot absolute left-3 top-1/2 transform -translate-y-1/2 w-3 h-3 rounded-full bg-blue-500"
}
const _hoisted_5 = { class: "py-3 px-4 text-gray-500 text-right whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Loader, { class: "w-8 h-8 text-blue" })
      ], 512), [
        [_vShow, _ctx.loading]
      ]),
      (!_ctx.loading && !_ctx.notifications.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Tú hevur onga nýggja fráboðan"))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.notifications.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Table, {
              title: "Fráboðanir",
              headings: [
					`${_ctx.notifications.length} ${_ctx.notifications.length == 1 ? 'Fráboðan' : 'Fráboðanir'}`,
					'Dagur',
				]
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
                  return (_openBlock(), _createBlock(_component_TableRow, {
                    key: notification.id,
                    onClick: (e) => _ctx.handleRowClick(e.target, notification.id, notification.data.url)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("td", {
                        class: _normalizeClass(["relative py-3 px-4 whitespace-nowrap", { 'pl-8 font-bold': !notification.read_at }])
                      }, [
                        (!notification.read_at)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(notification.data.message), 1)
                      ], 2),
                      _createElementVNode("td", _hoisted_5, _toDisplayString(new Date(notification.created_at).format('%-d. %mn3. %y kl. %H:%M')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["headings"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}