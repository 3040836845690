
import { defineComponent, onMounted, ref, Ref, watch } from 'vue';

import Store from '@/store';
import AuthService from '@/services/auth.service';
import NotificationService from '@/services/notification.service';

import Notification from '@/models/notification.model';

import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import Table from '@/components/table/Table.vue';
import TableRow from '@/components/table/Row.vue';

export default defineComponent({
	components: {
		Layout,
		Loader,
		Table,
		TableRow,
	},

	setup() {
		const user = Store.get('user');
		const loading = ref(true);
		const notifications: Ref<Notification[]> = ref([]);

		// load the projects
		onMounted(async () => {
			await NotificationService.get()
				.then((response: Notification[]) => {
					if (response) {
						notifications.value = response;
					}
				})
				.finally(() => {
					loading.value = false;
				});
		});

		watch(user, async () => {
			await NotificationService.get().then((response: Notification[]) => {
				if (response) {
					notifications.value = response;
				}
			});
		});

		async function handleRowClick(target: HTMLElement, id: string, url: string) {
			await NotificationService.toggle(id).then(async (notificationResponse) => {
				if (notificationResponse) {
					await AuthService.me(true);

					await NotificationService.get().then((response: Notification[]) => {
						if (response) {
							notifications.value = response;
						}
					});
				}
			});

			if (!target.classList.contains('blue-dot') && url) {
				window.location.href = url;
			}
		}

		return {
			// data
			loading,
			notifications,

			// functions
			handleRowClick,
		};
	},
});
